import { Component,ChangeDetectorRef,NgZone, HostListener } from '@angular/core';
import { AngularDelegate, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import {TranslateService} from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import {environment} from '../environments/environment';
import { Router,NavigationStart } from '@angular/router';
import { Hub,Auth,Amplify,Analytics } from 'aws-amplify';
import { APIService } from './API.service';
import { AlertController } from '@ionic/angular';
import { MenuController } from '@ionic/angular';
import { Plugins } from '@capacitor/core';
import {AnalyticsService} from './service/analytics.service';
import { DataTransferService } from './service/data-transfer.service';
const { Geolocation } = Plugins;
import { Renderer2, RendererFactory2, Inject, ElementRef } from '@angular/core';

@Component({ 
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  renderer:Renderer2;
  emailValue:any;
  timeOut:any;
  currentUrl:any="";
  isMobileApp=environment.isMobileApp;
  activeUrl:any="";
  userLogin:Boolean=false;
  userPoolId:any;
  tabMenuShow:Boolean=false;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translate: TranslateService,
    private http: HttpClient,
    private ref: ChangeDetectorRef,
    private router:Router,
    private ngZone:NgZone,
    private apiService: APIService,
    public alertController: AlertController,
    public menuCtrl: MenuController,
    public analytics: AnalyticsService,
    private dataTransfer:DataTransferService,
    private elementRef:ElementRef,
  ) {
    var poolName = localStorage.userPool;
    if(poolName){
      var userPool = environment.userPool.find(x => x.domain === poolName);
      this.userPoolId = userPool.aws_user_pools_id;
      Amplify.configure(userPool); 
    }    
    localStorage.transLang = localStorage.transLang ? localStorage.transLang : 'en';
    this.initializeApp();
    translate.setDefaultLang('en');
    translate.use('en');   
    Auth.currentSession().then(data=>{
      this.userLogin = true;
    },error=>{
      this.userLogin = false;
      setTimeout(()=>{
        var url = this.router.url?.split("?")[0];
        if(url!="/create-account" && url!="/reset-password")
          this.ngZone.run(() => this.router.navigate(['/sign-in']));
      },3000);
      //this.ngZone.run(() => this.router.navigate(['/sign-in']));
    });
    router.events.subscribe((val) => {  
      if(val instanceof NavigationStart) {
        this.activeUrl = val['url'].split("?")[0]; 
        var url = val['url'].split("?");
        var userLog = {type:"pageView",userId:localStorage.currentSub,dateTime:new Date(),url:val['url']};
        this.analytics.saveUserLogs(userLog);
        if(url[0] == "/sign-in" && url.length>1){
          setTimeout(()=>{
            var orguser = url[1].split('&');
            var orguser1 = orguser[0].split('=');
            var orguser2 = orguser[1].split('=');
            var username, orgname;
            if(orguser1[0]=="uname"){
              username=orguser1[1];
              orgname = orguser2[1];
            }else{
              username=orguser2[1];
              orgname = orguser1[1];
            }
            var re = /%20/gi; 
            var neworg = orgname.replace(re, " "); 
            var userPool = environment.userPool.find(x => x.domain == neworg);
            if(userPool){
              this.userPoolId = userPool.aws_user_pools_id;
              Amplify.configure(userPool); 
              this.dataTransfer.setUser({userName:username});
              this.router.navigate(['/reset-password']);
            }
          },2000);          
        }else{
          if(!sessionStorage.isLogin){
            var currentLocation = localStorage.currentLocation;
            console.log("this.router.url",url[0]);
            if(url[0]!="/create-account" && url[0] != "/sign-in" && url[0] != "/reset-password")
              this.signOutUser(currentLocation); 
          }
        }
      }      
    });
  }
  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }
  ngOnInit(){    
    this.onResize();
    if(this.userPoolId)
      this.getTenantConfig(this.userPoolId);
    this.checkIdleTimeout();
    this.checkUserSession();
    this.getMapquestKey();
    /*if(!sessionStorage.isLogin){
      var currentLocation = localStorage.currentLocation;
      console.log("this.router.url",this.router.url);
      //setTimeout(()=>{
        var url = this.router.url?.split("?")[0];
        if(url!="/create-account")
          this.signOutUser(currentLocation); 
      //},100);      
    }*/
    Hub.listen('auth', (data) => {
      switch (data.payload.event) {
      case 'signIn':
        this.userLogin = true;
        var userPool = environment.userPool.find(x => x.domain === localStorage.userPool);
        var userPoolId = userPool.aws_user_pools_id;
        this.getTenantConfig(userPoolId);
        break;
      case 'signOut':
        this.userLogin = false;
        break;
      }
    });
  }
  getTenantConfig(poolId){
    this.apiService.GetTenantConfig(poolId).then(data=>{
      if(data?.result.length>0){
        var tenantConfig = data?.result[0];
        for(let item of Object.keys(tenantConfig)){
          var key:any = "--"+item;
          var value = (key.replace(/([A-Z])/g, '-$1').trim()).toLowerCase();
          this.elementRef.nativeElement.style.setProperty(value, tenantConfig[item]);   
        }
      }
    }).catch(error=>{
      console.log(error);
    });
  }
  getMapquestKey(){
    this.apiService.GetParameterDataByName("mapquest_key").then(param=>{
      this.dataTransfer.setMapquestKey(param.result[0]?.Value);
    }).catch(error=>{
      console.log(error);
    });
  }
  federatedSignIn(){
    Auth.federatedSignIn({customProvider: 'SimbiQOpenID'});    
  }
  checkUserSession(){
    var checkinter = setInterval(()=>{
      if(sessionStorage.isLoginGetSession){
        Auth.currentAuthenticatedUser().then(user=>{
          var sub = user?.attributes["custom:subId"];
          this.apiService.GetSessionuserData("get",sub,"","loggedIn").then(data=>{
            var code = data["result"][0]["code"];
            if(sessionStorage.isLogin && code && localStorage.userRandomToken != code){
              clearInterval(checkinter);
              this.presentAlert("You have been signed out because your account is signed in from another device");
            }
          }).catch(error=>{
            console.log(error);
          });
        }).catch(error=>{
          console.log(error);
          var currentLocation = localStorage.currentLocation;
          this.signOutUser(currentLocation);
        }); 
      }
    },5000);
  }
  async presentAlert(msg) {
    const alert = await this.alertController.create({
      cssClass: 'custom-alert',
      message: msg,
      buttons: [{
        text: 'OK',
        cssClass: 'alertmsg-button',
        handler: () => {
          //var currentLocation = localStorage.currentLocation;
          //this.signOutUser(currentLocation);
          //this.checkUserSession();    
        }
      }]
    });
    await alert.present();

    const { role } = await alert.onDidDismiss();
    var currentLocation = localStorage.currentLocation;
    this.signOutUser(currentLocation);
    this.checkUserSession();
  }
  signOutUser(currentLocation){
    var userMail = localStorage.userMail;
    var userPool = localStorage.userPool;
    var userLog = {type:"loggedOut",userId:localStorage.currentSub,dateTime:new Date()};
    this.analytics.saveUserLogs(userLog);
    Auth.signOut().then(res=>{
      localStorage.clear();
      sessionStorage.clear();
      if(currentLocation && currentLocation!=undefined && currentLocation!='undefined'){
        localStorage.currentLocation = currentLocation;
        localStorage.defaultLocation = currentLocation;
        localStorage.defaultViewLocation = currentLocation;
      }
      if(userMail && userPool && userMail!='undefined' && userPool!='undefined'){
        localStorage.userMail = userMail;
        localStorage.userPool = userPool;
      }      
      this.router.navigate(['/sign-in']);
    },error=>{
      localStorage.clear();
      sessionStorage.clear();
      if(currentLocation && currentLocation!=undefined && currentLocation!='undefined'){
        localStorage.currentLocation = currentLocation;
        localStorage.defaultLocation = currentLocation;
        localStorage.defaultViewLocation = currentLocation;
      }
      if(userMail && userPool && userMail!='undefined' && userPool!='undefined'){
        localStorage.userMail = userMail;
        localStorage.userPool = userPool;
      }
      this.router.navigate(['/sign-in']);
    });
  }
  checkIdleTimeout(){
    this.timeOut =  setTimeout(() => {
      console.log("Session Timeout");
      var currentLocation = localStorage.currentLocation;
      this.signOutUser(currentLocation);
    }, 900000);
  }
  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e) {
    clearTimeout(this.timeOut);
    this.checkIdleTimeout();
  };
  @HostListener('document:click', ['$event'])
  onClick(e) {
    clearTimeout(this.timeOut);
    this.checkIdleTimeout();
  };
  @HostListener('window:keyup', ['$event'])
  onKeyup(e) {
    clearTimeout(this.timeOut);
    this.checkIdleTimeout();
  };
  closeMenu(){
    this.menuCtrl.close();
  }
  signOut() {
    this.menuCtrl.close();
    var currentLocation = localStorage.currentLocation;
    this.signOutUser(currentLocation);
  }
  routeChange(){
    this.currentUrl = this.router.url;
  }
  onResize(){
    if(window.innerWidth <= 640)
      this.tabMenuShow=true;
    else
      this.tabMenuShow=false;
  }
}